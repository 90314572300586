<template>
  <v-container
    v-if="$canSap('miCuentaCorriente')"
    id="crud"
    fluid
    tag="section"
    class="px-6"
  >
    <v-row>
      <v-col cols="12" md="12" class="py-0">
        <v-card elevation="0">
          <v-card-text class="px-3">
            <v-row>
              <!--
              <v-col cols="12" sm="4" md="3" lg="3" class="pa-2 pt-3">
                <label for="">Buscar Cliente o <br>Nro Comprobante</label>
                <v-text-field
                  v-model="search"
                  dense
                  outlined
                ></v-text-field>
              </v-col>
              -->
              <v-col cols="6" sm="4" md="3" lg="2" class="pa-2 pt-3" style="margin-top: 22px;">
                <label for="">Ente</label>
                <v-select
                  small
                  v-model="selectedEnte"
                  :items="entes"
                  dense
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3" sm="2" md="1" lg="1" class="pa-3 d-flex align-center mt-4">
                <v-btn
                  color="primary"
                  small
                  class="custom-btn"
                  @click="buscar()"
                  style=" text-transform: unset !important; "
                >Buscar
                  <v-icon right>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="3" sm="2" md="1" lg="1" class="pa-3 d-flex align-center mt-4">
                <v-btn
                  color="success"
                  small
                  class="custom-btn"
                  @click="descargar()"
                  style=" text-transform: unset !important; "
                >Descargar
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="12" md="4" lg="4" class="d-flex justify-end pa-1">
                <div elevation="1" class="totales-div elevation-1 no-padding-top">
                  <div class="pa-2 text-subtitle-1 font-weight-bold">
                    Total
                  </div>
                  <div class="pa-2">
                    <v-row no-gutters>
                      <v-col cols="6" class="text-center">
                        <div class="totales-valor">{{ $formatARS(saldoAcara) }}</div>
                        <div class="totales-subtitulo">Acara</div>
                      </v-col>
                      <v-col cols="1" class="totales-separador pa-0">
                        <div class="primary totales-separador-line"></div>
                      </v-col>
                      <v-col cols="5" class="text-center">
                        <div class="totales-valor">{{ $formatARS(saldoMj) }}</div>
                        <div class="totales-subtitulo">MJ</div>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="12" class="pt-0">
        <v-card>
          <v-data-table
            :headers="headers"
            :items="desserts"
            class="elevation-1 custom-striped-table"
            :footer-props="{
              'items-per-page-options': [10, 20],
              'show-first-last-page': true,
              'first-icon': 'mdi-page-first',
              'last-icon': 'mdi-page-last',
              'page-text': '{0}-{1} de {2}',
            }"
            :items-per-page="10"
            style="width:100%;"
          >



<!--
        <template v-slot:[`item.DocDate`]="{ item }">
          <td style="vertical-align: middle;">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{item.DocDate? $formatDate(item.DocDate,true):'' }}
            </div>
          </td>
        </template>
        <template v-slot:[`item.DocEntry`]="{ item }">
          <td style="vertical-align: middle;">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{item.legalNumber  }}
            </div>
          </td>
        </template>
        <template v-slot:[`item.SaldoAcum`]="{ item }">
          <td style="vertical-align: middle;">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              <span :style="{ color: item.TC !== 'Factura' ? 'black' : 'inherit' }">
                      ${{ $formatCurrencyValue(item.SaldoAcum) }}
                    </span>
            </div>
          </td>


        </template>

        <template v-slot:[`item.DocDate`]="{ item }">
          <td style="vertical-align: middle;">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{item.DocDate? $formatDate(item.DocDate,true):'' }}
            </div>
          </td>
        </template>
        <template v-slot:[`item.DocEntry`]="{ item }">
          <td style="vertical-align: middle;">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{item.legalNumber  }}
            </div>
          </td>
        </template>
        <template v-slot:[`item.SaldoAcum`]="{ item }">
          <td style="vertical-align: middle;">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              {{item.SaldoAcum  }}
            </div>
          </td>
        </template>
   -->


          <template v-slot:item="{ item }" >
             <tr >
                <td class="text-center" style="width:40px;background-color: #efefef">
                    <v-icon
                      v-if="item.TC === 'Factura' ||item.TC === 'Nota de Credito'"
                      small
                      color="primary"
                      @click="descargarFactura(item)"
                    >
                      mdi-file-document
                    </v-icon>
                  </td>
                  <td class="text-left pa-0" style="width: 80px;background-color: #efefef;font-size:0.8rem;">{{ item.DocDate? $formatDate(item.DocDate,true):'' }}</td>
                  <td class="text-left pa-0" style="width: 100px;background-color: #efefef;font-size:0.8rem;">{{ item.DB }}</td>
                  <td class="text-left pa-0" style="width: 200px;background-color: #efefef;font-size:0.8rem;">{{ item.CardName }}</td>
                  <td class="text-center pa-0" style="width: 100px;background-color: #efefef;font-size:0.8rem;">{{ item.TC }}</td>
                  <td class="text-right pa-0" style="width: 150px;background-color: #efefef;font-size:0.8rem;">{{ item.legalNumber }}</td>
                  <td class="text-right pa-0" style="width: 100px;background-color: #efefef;font-size:0.8rem;">
                    <span :style="{ color: item.TC !== 'Factura' ? 'red' : 'inherit' }">
                      ${{ item.TC !== 'Factura' ? $formatCurrencyValue(0) : $formatCurrencyValue(item.DocTotal)  }}
                    </span>
                  </td>
                  <td class="text-right  pa-0" style="width:100px;background-color: #efefef;font-size:0.8rem;">
                    <span :style="{ color: item.TC !== 'Factura' ? 'red' : 'inherit' }">
                       ${{ item.TC !== 'Factura' ? $formatCurrencyValue(item.DocTotal) : $formatCurrencyValue(0)  }}
                    </span>
                  </td>



                  <td class="text-right pa-0 pr-2" style="width:100px;background-color: #efefef;font-size:0.8rem;">
                    <span :style="{ color: item.SaldoAcum < 0 ? 'red' : 'inherit' }">
                      ${{ $formatCurrencyValue(item.SaldoAcum) }}
                    </span>
                  </td>
              </tr>





              <!-- Fila adicional solo si ReciboDocEntry tiene valor -->

              <!--
              <tr v-if="item.ReciboDocEntry" style="">
                <td class="text-left recibo" style="width: 20px; "></td>
                <td class="text-left recibo" style="width: 10%; ">{{ item.FechaRecibo? $formatDate(item.FechaRecibo,true):'' }}</td>
                <td class="text-left recibo" style="width: 15%; ">{{ item.DB }}</td>
                <td class="text-left recibo" style="width: 20%; ">{{ item.CardName }}</td>
                <td class="text-left recibo" style="width: 15%; ">Recibo</td>
                <td class="text-left recibo" style="width: 15%; ">{{ item.NumeroRecibo }}</td>
                <td class="text-right recibo" style="width: 8%; ">
                <span :style="{ color: item.TC == 'Factura' && item.TotalRecibo >=0  ? 'red' : 'inherit' }">
                  ${{$formatCurrencyValue(0)}}
                </span>
                </td>
                <td class="text-right recibo" style="width: 8%; ">
                  <span :style="{ color: item.TC == 'Factura' && item.TotalRecibo >=0  ? 'red' : 'inherit' }">
                    ${{ $formatCurrencyValue(item.TotalRecibo) }}
                  </span>
                </td>
                <td class="text-right recibo" style="width: 8%; ">
                  <span :style="{ color: item.TC !== 'Factura' ? 'black' : 'inherit' }">
                    ${{ $formatCurrencyValue(item.SaldoAcum) }}
                  </span>
                </td>
              </tr>
              -->

            </template>



            <template v-slot:no-data>
              <div class="text-center pa-4" v-if="!busquedaRealizada">
                <v-icon color="primary" size="65">mdi-magnify</v-icon>
                <p class="mt-2">Aún no hay datos para mostrar. Realiza una búsqueda utilizando los campos anteriores</p>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :color="color"
      :timeout="timeout"
    >
      <div v-html="text"></div>

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>


<script>
import DialogFormValidate from "@/views/dialogs/FormValidate.vue";
import DialogFormCompleted from "@/views/dialogs/FormCompleted.vue";
import DialogFormConfirm from "@/views/dialogs/FormConfirmar.vue";

export default {
  components: {
    DialogFormValidate,
    DialogFormCompleted,
    DialogFormConfirm
  },
  name: "CuentasCorrientes",
  data() {
    return {
      route:"currentAccount",
      search: "",
      selectedEnte: null,
      entes: [this.$db("ACARA"),this.$db("MJ")],
      busquedaRealizada: false,
      headers: [
        {
          text: "",
          value: "actions",
          sortable: false,
        },
        { text: "Fecha", value: "DocDate", sortable: false  },
        { text: "Ente", value: "DB", sortable: false  },
        { text: "Cliente", value: "CardName", sortable: false  },
        {
          text: "T. Comprob.",
          value: "TC",
          sortable: false
        },
        {
          text: "N° Comprobante",
          value: "DocEntry",
          sortable: false
        },
        { text: "Debe", value: "DocTotal", sortable: false  },
        { text: "Haber", value: "PaidToDate", sortable: false  },
        { text: "Saldo", value: "SaldoAcum", sortable: false  },

      ],
      saldoAcara: 0,
      saldoMj: 0,
      desserts: [],
      text:"",
      snackbar:false,
      color: "success",
      timeout: 4000,

    };
  },
  computed: {
    filteredDesserts() {
      // Si no hay búsqueda realizada, no mostramos nada
      /*if (!this.busquedaRealizada) {
        return [];
      }*/

      let filtered = this.desserts;
      /*if (this.search) {
        const searchTerm = this.search.toLowerCase();
        filtered = filtered.filter(item => {
          return item.Cliente.toLowerCase().includes(searchTerm) ||
            item.Nro_Comprobante.toLowerCase().includes(searchTerm);
        });
      }
      if (this.selectedEnte) {
        filtered = filtered.filter(item => item.Ente === this.selectedEnte);
      }*/
      return filtered;
    }
  },
  created() {
    this.getElementos();

  },
  methods: {

    async fetchSaldoAFavor() {
      try {
        const cliente = this.$store.state.user.sapProperties.CardCode;
        const bases =  this.$db("ACARA")+','+this.$db("MJ");
        const queryString = `${cliente}/${bases}`;

        let saldos =  await this.$fetchSaldoAFavor('consultar', cliente, bases);

        //let saldos = false;

        //const response = await this.$axiosApi.getById("saldoCuentaCorriente", queryString);

        /*if (response.data.status === "Success" && response.data.data) {
            saldos = response.data.data;
        }*/
        console.log(saldos);
        if (saldos) {
          for (let key in saldos) {
            if(key==this.$db("ACARA")){
              this.saldoAcara = saldos[key].CurrentAccountBalance;
            }
            else{
              this.saldoMj = saldos[key].CurrentAccountBalance;
            }
  //console.log(`${key}: ${saldos[key]}`);
}
        } else {
          console.error('No se pudieron obtener los saldos.');
        }
      } catch (error) {
        console.error('Error al consultar los saldos:', error);
      }
    },

    getElementos() {

      Swal.alertGetInfo("Buscando información");
      console.log(this.$store.state.user.username.toUpperCase());
      console.log();

      //let filter = this.$store.state.user.username.toUpperCase()+'/10000';
      let filter = this.$store.state.user.sapProperties.CardCode+'/10000';
      this.$axiosApi
      .getByCriteria(this.route, filter)
        .then((r) => {
          console.log(r.data);
          if (r.data) {
            this.desserts = r.data.data;
            //this.mergeElementoConCarrito();

          }

          Swal.close();
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";

          Swal.close();
        });
        this.fetchSaldoAFavor();
    },
    buscar() {
      this.busquedaRealizada = true;

      Swal.alertGetInfo("Buscando información");
      console.log(this.$store.state.user.username.toUpperCase());

      //let filter = this.$store.state.user.username.toUpperCase()+'/10000';
       let filter = this.$store.state.user.sapProperties.CardCode+'/10000';
      //let filter = 'R028034/10000';

      let params = {
        comprobante: this.search || '', // Usa un valor vacío si `this.search` no existe
        db: this.selectedEnte || '',   // Usa un valor vacío si `this.selectedEnte` no existe
      };

      // Filtrar solo los parámetros que tienen valores
      params = Object.fromEntries(Object.entries(params).filter(([_, value]) => value));

      // Construir la URL con los parámetros
      filter = Object.keys(params).length
        ? `${filter}?${new URLSearchParams(params).toString()}`
        : filter;


      this.$axiosApi
      .getByCriteria(this.route, filter)
        .then((r) => {
          console.log(r.data);
          if (r.data) {
            this.desserts = r.data.data;
            //this.mergeElementoConCarrito();
          }

          Swal.close();
        })
        .catch((error) => {
          console.log(error);
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error;
          this.color = "error";

          Swal.close();
        });

    },
    descargar() {
      //console.log("Descargando Listado:", this.desserts);

      Swal.alertGetInfo("Descargando información");
      //console.log(this.$store.state.user.username.toUpperCase());
      //let filter = this.$store.state.user.username.toUpperCase()+'/10000';
      //let filter = 'R028034/10000';
      let filter = this.$store.state.user.sapProperties.CardCode+'/10000';


      let params = {
        comprobante: this.search || '', // Usa un valor vacío si `this.search` no existe
        db: this.selectedEnte || '',   // Usa un valor vacío si `this.selectedEnte` no existe
      };

      // Filtrar solo los parámetros que tienen valores
      params = Object.fromEntries(Object.entries(params).filter(([_, value]) => value));

      // Construir la URL con los parámetros
      filter = Object.keys(params).length
        ? `${filter}?${new URLSearchParams(params).toString()}`
        : filter;



      this.$axiosApi
      .getDownload("currentAccountExport", filter) // Importante: responseType como 'blob'.
        .then((response) => {
          // Crear un enlace para descargar el archivo.
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;

          const fechaActual = new Date();

          const año = fechaActual.getFullYear();
          const mes = String(fechaActual.getMonth() + 1).padStart(2, '0'); // Asegura dos dígitos
          const dia = String(fechaActual.getDate()).padStart(2, '0'); // Asegura dos dígitos

          const fechaFormateada = `${año}-${mes}-${dia}`;

          link.setAttribute("download", "Mi-Cuenta-Corriente-"+fechaFormateada+".xlsx"); // Nombre del archivo.
          document.body.appendChild(link);
          link.click();
          link.remove();

          Swal.close();
        })
        .catch( (error) =>{
          console.log(error);
          this.snackbar = true;
          this.text = "Error al obtener datos. Error: " + error.message;
          this.color = "error";

          Swal.close();
        });
    },
    descargarFactura(item) {
      //console.log(item);
      Swal.alertGetInfo("Buscando información");
      let id = item.DocEntry;
      let db = item.DB;
      let url = "/api/servicelayer/pdf/ctacte/"+id+"/"+db;

      this.$axiosApi
        .sendRequestPdfLoad(url, "GET")
        .then((response) => {
          Swal.close();
          //this.isLoading = false;
          if (response.data.size <= 0) {
            Swal.fire("Atención!", "No se pudieron obtener los datos", "info");
            return;
          }
          let blob = new Blob([response.data], {
              type: response.data.type,
            }),
            url = window.URL.createObjectURL(blob);

          window.open(url);
        })
        .catch(console.error);
    },

  },
};
</script>

<style>
.custom-striped-table .v-data-table__wrapper table tbody tr:nth-child(odd) {
  background-color: #f5f5f5 !important;
}
.custom-striped-table .v-data-table__wrapper table tbody tr:nth-child(even) {
  background-color: #ffffff !important;
}

.capitalize-first {
  text-transform: lowercase;
}

.capitalize-first::first-letter {
  text-transform: uppercase;
}

.totales-valor {
  font-size: 1rem;
}

.no-padding-top {
  margin-top: 0 !important;
  width: 100%;
}

.totales-subtitulo {
  font-size: 0.8rem;
  color: grey;
}

.totales-separador {
  padding: 0 !important;
}
.totales-separador-line{
  height: 100%;
  width: 2px;
}

.recibo
{
  border-top: #dfdfdf solid 1px;
  margin-left: 14px !important;
  background: #fff;
}


</style>
